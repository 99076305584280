import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { pxToRem } from "../../utils/px-to-rem";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { enterTextViaScrambling } from "../../utils/text-animations";
import { Text } from "../Text";

interface IFunFactsConstellation {
    onComplete: () => void;
}

const SvgFfConstellation = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            id="fun-facts-constellation-svg"
            css={{ overflow: "visible" }}
            viewBox="0 0 293 393"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {/*<circle className="knob" id="knob-2" r="25" fill="#88CE02" strokeWidth="4" stroke="#111" />*/}
            {/*<circle className="knob" id="knob-3" r="25" fill="#88CE02" strokeWidth="4" stroke="#111" />*/}
            {/*<circle className="knob" id="knob-4" r="25" fill="#88CE02" strokeWidth="4" stroke="#111" />*/}
            <g id="bottom-star" filter="url(#filter-bottom-star)">
                <path
                    d="M187.18 343.047c.064-.729 1.105-.792 1.257-.077a.637.637 0 001.142.236c.419-.589 1.341-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.827.703-.259 1.274.638.709 1.13l-.267.232a.674.674 0 00.251 1.156l.336.099c.713.211.609 1.252-.131 1.319l-.235.021a.675.675 0 00-.46 1.101l.226.274c.469.567-.211 1.356-.842.978a.654.654 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.155.07c-.245.679-1.254.48-1.223-.241l.006-.156a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.235.007c-.744.024-.973-.997-.291-1.293l.321-.139a.674.674 0 00.109-1.178l-.293-.198c-.621-.419-.163-1.379.566-1.207.544.128 1.012-.42.783-.93l-.113-.252c-.296-.661.563-1.225 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g id="left-star" filter="url(#filter-left-star)">
                <path
                    d="M44.008 204.757c.064-.729 1.105-.792 1.257-.077a.637.637 0 001.142.236c.42-.59 1.34-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.827.703-.259 1.274.638.709 1.13l-.267.232a.675.675 0 00.251 1.156l.336.099c.713.211.61 1.252-.131 1.319l-.235.021a.674.674 0 00-.46 1.101l.226.274c.469.567-.211 1.356-.842.978a.654.654 0 00-.983.668l.026.154c.119.712-.859 1.032-1.184.388a.63.63 0 00-1.156.07c-.245.679-1.254.48-1.223-.241l.007-.156a.654.654 0 00-1.057-.544c-.58.453-1.35-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.235.007c-.744.024-.973-.997-.29-1.293l.32-.139a.675.675 0 00.11-1.178l-.294-.198c-.62-.419-.163-1.379.566-1.207.544.128 1.012-.42.783-.93l-.113-.252c-.296-.661.564-1.225 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g id="right-star" filter="url(#filter-right-star)">
                <path
                    d="M246.897 160.823c.064-.728 1.104-.792 1.257-.077a.637.637 0 001.141.237c.42-.59 1.341-.135 1.128.557l-.082.264c-.165.534.366 1.021.89.828.703-.26 1.274.638.708 1.129l-.266.232a.674.674 0 00.251 1.156l.335.1c.714.211.61 1.251-.131 1.318l-.234.021a.675.675 0 00-.46 1.101l.226.274c.469.568-.211 1.357-.842.978a.655.655 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.156.071c-.245.679-1.254.48-1.222-.241l.006-.157a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.299a.674.674 0 00-.59-1.037l-.236.007c-.743.024-.972-.996-.29-1.292l.321-.14a.674.674 0 00.109-1.177l-.293-.198c-.621-.42-.163-1.38.566-1.208.544.128 1.012-.42.783-.929l-.113-.253c-.296-.66.563-1.224 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            <g id="top-star" filter="url(#filter-top-star)">
                <path
                    d="M116.927 40.62c.064-.727 1.104-.79 1.257-.076a.637.637 0 001.142.236c.419-.59 1.34-.134 1.127.557l-.082.265c-.165.534.366 1.02.89.828.703-.26 1.274.638.709 1.13l-.267.231a.674.674 0 00.251 1.156l.335.1c.714.21.61 1.251-.131 1.318l-.234.021a.675.675 0 00-.46 1.101l.226.274c.469.567-.211 1.357-.842.978a.655.655 0 00-.983.668l.026.154c.118.712-.859 1.032-1.185.388a.63.63 0 00-1.155.07c-.246.68-1.255.48-1.223-.24l.006-.157a.654.654 0 00-1.056-.544c-.58.453-1.351-.248-.955-.868l.191-.3a.674.674 0 00-.59-1.036l-.236.007c-.743.024-.972-.996-.29-1.293l.321-.139a.674.674 0 00.109-1.178l-.293-.197c-.621-.42-.163-1.38.566-1.208.544.128 1.012-.42.783-.93l-.113-.252c-.296-.66.563-1.224 1.051-.69a.637.637 0 001.105-.373z"
                    fill="#FF8B38"
                />
            </g>
            {/*{x: 44.922000885009766, y: 45.2599983215332, width: 72.91799926757812, height: 164.13600158691406}*/}
            <path
                id="left-to-top-line-path"
                d="M44.922 209.396L117.84 45.26"
                // stroke="url(#left-to-top-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                id="left-to-top-line-path-indicator"
                d="M44.922 209.396L117.84 45.26"
                stroke="url(#left-to-top-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            {/*{x: 117.74299621582031, y: 45.02000045776367, width: 130.2570037841797, height: 119.97999572753906}*/}
            <path
                id="top-to-right-line-path"
                d="M117.743 45.02L248 165"
                // stroke="url(#top-to-right-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                id="top-to-right-line-path-indicator"
                d="M117.743 45.02L248 165"
                stroke="url(#top-to-right-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            {/*{x: 45, y: 209, width: 142.87399291992188, height: 139}*/}
            <path
                id="bottom-to-left-line-path"
                d="M187.874 348L45 209"
                // stroke="url(#bottom-to-left-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                id="bottom-to-left-line-path-indicator"
                d="M187.874 348L45 209"
                stroke="url(#bottom-to-left-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            {/*{x: 188, y: 165, width: 59.873992919921875, height: 183}*/}
            <path
                id="right-to-bottom-line-path"
                d="M247.874 165L188 348"
                // stroke="url(#right-to-bottom-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />
            <path
                id="right-to-bottom-line-path-indicator"
                d="M247.874 165L188 348"
                stroke="url(#right-to-bottom-line)"
                strokeOpacity={0.4}
                strokeWidth={1.833}
            />

            <circle
                css={{ zIndex: 30 }}
                className="knob"
                id="knob-1"
                r="25"
                fill="#FF8B38"
                opacity="0.05"
                strokeWidth="4"
                stroke="#111"
            />
            <defs>
                {/*{x: 44.922000885009766, y: 45.2599983215332, width: 72.91799926757812, height: 164.13600158691406}*/}
                <linearGradient
                    id="left-to-top-line"
                    x1={117}
                    y1={45}
                    x2={44.922}
                    y2={209.396}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0.1} offset={0.01} />
                    <stop offset={0.01} stopColor="#FF8B38" />
                    <stop offset={0.01} stopOpacity={0.1} stopColor="#FF8B38" />
                </linearGradient>
                {/*{x: 117.74299621582031, y: 45.02000045776367, width: 130.2570037841797, height: 119.97999572753906}*/}
                <linearGradient
                    id="top-to-right-line"
                    x1={248}
                    y1={165}
                    x2={117.74299621582031}
                    y2={33.701}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0.1} offset={0.01} />
                    <stop offset={0.01} stopColor="#FF8B38" />
                    <stop offset={0.01} stopOpacity={0.1} stopColor="#FF8B38" />
                </linearGradient>
                {/*{x: 45, y: 209, width: 142.87399291992188, height: 139}*/}
                <linearGradient
                    id="bottom-to-left-line"
                    x1={45}
                    y1={209}
                    x2={188}
                    y2={358.757}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0.1} offset={0.01} />
                    <stop offset={0.01} stopColor="#FF8B38" />
                    <stop offset={0.01} stopOpacity={0.1} stopColor="#FF8B38" />
                </linearGradient>
                {/*{x: 188, y: 165, width: 59.873992919921875, height: 183}*/}
                <linearGradient
                    id="right-to-bottom-line"
                    x1={248}
                    y1={348}
                    x2={188}
                    y2={165}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8B38" stopOpacity={0.1} offset={0.01} />
                    <stop offset={0.01} stopColor="#FF8B38" />
                    <stop offset={0.01} stopOpacity={0.1} stopColor="#FF8B38" />
                </linearGradient>
                <filter
                    id="filter-bottom-star"
                    x={143.244}
                    y={302.466}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter-left-star"
                    x={0.073}
                    y={164.176}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter-right-star"
                    x={202.961}
                    y={120.242}
                    width={89.782}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter-top-star"
                    x={72.991}
                    y={0.04}
                    width={89.781}
                    height={90.397}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={7.5} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.688333 0 0 0 0 0.220833 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_110_834"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={20} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.690196 0 0 0 0 0.219608 0 0 0 1 0" />
                    <feBlend
                        in2="effect1_dropShadow_110_834"
                        result="effect2_dropShadow_110_834"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_110_834"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

type IConstellationAnimations =
    | "topToRight"
    | "rightToBottom"
    | "bottomToLeft"
    | "leftToTop";
const FunFactsConstellation = ({ onComplete }: IFunFactsConstellation) => {
    const mainRef = useRef(null);
    const [isComplete, setIsComplete] = useState(false);
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(MotionPathPlugin);
    gsap.registerPlugin(InertiaPlugin);
    gsap.registerPlugin(DrawSVGPlugin);

    let topToRightPath;
    let topToRightPathGradient;
    let rightToBottomPath;
    let rightToBottomPathGradient;
    let bottomToLeftPath;
    let bottomToLeftPathGradient;
    let leftToTopPath;
    let leftToTopPathGradient;

    const [currentAnimation, setCurrentAnimation] =
        useState<IConstellationAnimations>("leftToTop");

    const setNextAnimation = () => {
        if (currentAnimation === "leftToTop") {
            setCurrentAnimation("topToRight");
        } else if (currentAnimation === "topToRight") {
            setCurrentAnimation("rightToBottom");
        } else if (currentAnimation === "rightToBottom") {
            setCurrentAnimation("bottomToLeft");
        } else if (currentAnimation === "bottomToLeft") {
            setIsComplete(true);
        }
    };
    let mainTl = null;
    let onCompleteTl = null;
    let draggable = null;
    let indicationTl = null;
    useEffect(() => {
        const ctx = gsap.context(() => {
            topToRightPath = document.querySelector<SVGGraphicsElement>(
                "#top-to-right-line-path"
            );
            topToRightPathGradient =
                document.querySelector<SVGGraphicsElement>(
                    "#top-to-right-line"
                );
            const topToRightPathIndicator =
                document.querySelector<SVGGraphicsElement>(
                    "#top-to-right-line-path-indicator"
                );

            rightToBottomPath = document.querySelector<SVGGraphicsElement>(
                "#right-to-bottom-line-path"
            );
            const rightToBottomPathIndicator =
                document.querySelector<SVGGraphicsElement>(
                    "#right-to-bottom-line-path-indicator"
                );
            rightToBottomPathGradient =
                document.querySelector<SVGGraphicsElement>(
                    "#right-to-bottom-line"
                );

            bottomToLeftPath = document.querySelector<SVGGraphicsElement>(
                "#bottom-to-left-line-path"
            );
            const bottomToLeftPathIndicator =
                document.querySelector<SVGGraphicsElement>(
                    "#bottom-to-left-line-path-indicator"
                );
            bottomToLeftPathGradient =
                document.querySelector<SVGGraphicsElement>(
                    "#bottom-to-left-line"
                );

            leftToTopPath = document.querySelector<SVGGraphicsElement>(
                "#left-to-top-line-path"
            );
            const leftToTopPathIndicator =
                document.querySelector<SVGGraphicsElement>(
                    "#left-to-top-line-path-indicator"
                );
            leftToTopPathGradient =
                document.querySelector<SVGGraphicsElement>("#left-to-top-line");

            const allPaths = {
                topToRightPath: topToRightPath,
                rightToBottomPath: rightToBottomPath,
                bottomToLeftPath: bottomToLeftPath,
                leftToTopPath: leftToTopPath
            };

            const allGradients = {
                topToRightPathGradient: topToRightPathGradient,
                rightToBottomPathGradient: rightToBottomPathGradient,
                bottomToLeftPathGradient: bottomToLeftPathGradient,
                leftToTopPathGradient: leftToTopPathGradient
            };

            const allIndicatorPaths = {
                topToRightPathIndicator: topToRightPathIndicator,
                rightToBottomPathIndicator: rightToBottomPathIndicator,
                bottomToLeftPathIndicator: bottomToLeftPathIndicator,
                leftToTopPathIndicator: leftToTopPathIndicator
            };

            const currentPath = allPaths[`${currentAnimation}Path`];

            const pathGradientStops =
                allGradients[
                    `${currentAnimation}PathGradient`
                ].querySelectorAll("stop");
            gsap.set(
                [
                    topToRightPathIndicator,
                    rightToBottomPathIndicator,
                    leftToTopPathIndicator,
                    bottomToLeftPathIndicator
                ],
                { autoAlpha: 0 }
            );
            gsap.to(allIndicatorPaths[`${currentAnimation}PathIndicator`], {
                autoAlpha: 1,
                delay: 3
            });

            indicationTl = gsap
                .timeline({
                    paused: true,
                    repeat: -1,
                    repeatDelay: 0.1,
                    yoyo: true
                })
                .fromTo(
                    pathGradientStops[0],
                    {
                        attr: {
                            offset: "45%"
                        }
                    },
                    {
                        attr: {
                            offset: "0%"
                        },
                        duration: 1.5
                    }
                )
                .fromTo(
                    pathGradientStops[1],
                    {
                        attr: {
                            offset: "60%"
                        }
                    },
                    {
                        attr: {
                            offset: "0%"
                        },
                        duration: 1.5
                    },
                    "="
                )
                .fromTo(
                    pathGradientStops[2],
                    {
                        attr: {
                            offset: "99%"
                        }
                    },
                    {
                        attr: {
                            offset: "0%"
                        },
                        duration: 1.5
                    },
                    "="
                );
            indicationTl.play();

            var D = document.createElement("div");
            gsap.set("#knob-1", {
                x: () => currentPath.getBBox().x,
                y: () => currentPath.getBBox().y
            });
            mainTl = gsap
                .timeline({
                    paused: true,
                    onComplete: () => {
                        gsap.set(currentPath, {
                            strokeOpacity: 1,
                            strokeWidth: "2px",
                            stroke: "rgba(255, 139, 56, 0.9)"
                        });
                        setNextAnimation();
                    }
                })
                .fromTo(
                    currentPath,
                    {
                        drawSVG: "0%",
                        strokeOpacity: 1,
                        strokeWidth: "2px",
                        stroke: "rgba(255, 139, 56, 0.9)",
                        ease: "linear",
                        duration: 1
                    },
                    {
                        drawSVG: "100%",
                        strokeOpacity: 1,
                        strokeWidth: "2px",
                        stroke: "rgba(255, 139, 56, 0.9)",
                        duration: 1,
                        ease: "linear"
                    }
                )
                .to(
                    "#knob-1",
                    1,
                    {
                        motionPath: {
                            path: currentPath,
                            align: currentPath,
                            alignOrigin: [0.5, 0.5]
                        },
                        ease: "none",
                        immediateRender: true
                    },
                    0
                );
            if (!isComplete) {
                draggable = new Draggable(D, {
                    trigger: "#knob-1",
                    type: "y",
                    throwProps: true,
                    inertia: true,
                    // bounds: document.getElementById("fun-facts-constellation-svg-container"),
                    // bounds: {top: rightToBottomPath.getBBox().y, left: rightToBottomPath.getBBox().x, width: rightToBottomPath.getBBox().width, height: rightToBottomPath.getBBox().height},
                    onDrag:
                        ["topToRight", "rightToBottom"].indexOf(
                            currentAnimation
                        ) > -1
                            ? updateVerticalDown
                            : updateVerticalUp,
                    // lockAxis: true,
                    dragResistance: 0.2,
                    // edgeResistance: 0,
                    onThrowUpdate:
                        ["topToRight", "rightToBottom"].indexOf(
                            currentAnimation
                        ) > -1
                            ? updateVerticalDown
                            : updateVerticalUp
                });
            } else {
                draggable && draggable.kill();
            }

            function updateVerticalDown() {
                if (this.y > 0) {
                    const progress = this.y / currentPath.getTotalLength();
                    mainTl.progress(Math.abs(progress));
                } else mainTl.progress(0);
            }

            function updateVerticalUp() {
                if (this.y < 0) {
                    const progress = this.y / currentPath.getTotalLength();
                    mainTl.progress(Math.abs(progress));
                } else {
                    mainTl.progress(0);
                }
            }

            if (isComplete) {
                mainTl.progress(1);
                gsap.set(currentPath, {
                    strokeOpacity: 1,
                    strokeWidth: "2px",
                    stroke: "rgba(255, 139, 56, 0.9)"
                });
                gsap.to("#knob-1", {
                    autoAlpha: 0
                });
                setTimeout(() => {
                    onCompleteTl = gsap
                        .timeline({
                            repeat: -1
                        })
                        .to("svg", {
                            rotate: "20deg",
                            duration: 1,
                            ease: "none"
                        })
                        .to("svg", {
                            rotate: "-10deg",
                            duration: 1,
                            ease: "none"
                        })
                        .to("svg", {
                            rotate: "0deg",
                            duration: 0.5,
                            ease: "none"
                        });

                    gsap.delayedCall(0.5, onComplete);
                    mainTl.kill();
                }, 500);
            }
        }, mainRef);

        return () => ctx.revert();
    }, [currentAnimation, isComplete]);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({ paused: false, delay: 0 })
                .add(
                    enterTextViaScrambling(
                        gsap.utils.toArray("#ff-constellation-caption"),
                        2
                    ).play()
                )
                .from(".svg-container", {
                    autoAlpha: 0
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    return (
        <div
            id="fun-facts-constellation-svg-container"
            ref={mainRef}
            css={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: pxToRem(80),
                display: "grid",
                gridGap: pxToRem(40),
                justifyItems: "center",
                overflow: "scroll"
            }}
        >
            <Text
                id="ff-constellation-caption"
                variant="caption"
                color="white"
                css={{
                    maxWidth: pxToRem(700),
                    margin: "auto"
                }}
            >
                Make constellation to travel in space
            </Text>
            {/*<div css={{ width: pxToRem(80) }}>*/}
            {/*    <img css={{ width: "100%" }} src="/images/ff-constellation-star.png" />*/}
            {/*</div>*/}
            <div className="svg-container" css={{ width: pxToRem(300) }}>
                <SvgFfConstellation />
            </div>
        </div>
    );
};

export default FunFactsConstellation;
